<template lang="pug">
.footer(:class="mini && '_mini'")
  .footer-menu(v-if="!mini")
    .footer-menu__column
      .footer-menu__title О компании
      a(target="_blank" href="https://nposys.ru/company/") Компания
      a(target="_blank" href="https://nposys.ru/contact/") Контакты
      a(target="_blank" href="https://nposys.ru/answer/") Вопрос-ответ
      a(target="_blank" href="https://nposys.ru/references/") Отзывы
      a(target="_blank" href="https://nposys.ru/vacancies/") Вакансии
      a(target="_blank" href="https://nposys.ru/news/") Последние новости
      a(target="_blank" href="https://nposys.ru/provereno-na-praktike/") Проверено на практике
      a(target="_blank" href="https://nposys.ru/blog/") Блог
    .footer-menu__column
      .footer-menu__title Услуги
      a(target="_blank" href="https://nposys.ru/owner/") Тендерное сопровождение
      a(target="_blank" href="https://nposys.ru/expert-search/") Экспертный поиск тендеров
      a(target="_blank" href="https://nposys.ru/legal-advice/") Юридические консультации
      a(target="_blank" href="https://nposys.ru/complaint/") Обжалование закупок
      a(target="_blank" href="https://nposys.ru/bank-guarantee/") Банковские гарантии
      a(target="_blank" href="https://nposys.ru/application/") Подготовка заявок
    .footer-menu__block
      .footer-menu__column
        .footer-menu__title Информация
        a(target="_blank" href="https://nposys.ru/policy/") Политика конфиденциальности
        a(target="_blank" href="https://nposys.ru/agreement/") Пользовательское соглашение
      .footer-menu__column
        .footer-menu__title Действия
        a.download-offer(target="_blank" download="Поиск тендеров от компании ООО НПО Система.pdf" href="/offer.pdf")
          ui-icon.download-icon(:size="16" :icon="UiIconNames.Icon_Download")
          span Скачать коммерческое предложение
    .footer-menu__block
      .footer-menu__column
        .footer-menu__title Адрес
        span г. Новосибирск, ул. Демакова, д. 30, 5 этаж, пн-пт с 9:00 до 18:00
        span г. Новосибирск, ул. Максима Горького, д. 53, оф. 709 пн-пт с 9:00 до 18:00
      .footer-menu__column
        .footer-menu__title Телефон
        a(:href="PhoneNumber.link" @click="sendMetrica(YmGoals.ContactsClick)") {{ PhoneNumber.value }}
        a(:href="FreePhoneNumber.link" @click="sendMetrica(YmGoals.ContactsClick)") {{ FreePhoneNumber.value }} бесплатно по РФ
        a.green(:href="WhatsAppNumber.link" target="_blank" @click="sendMetrica(YmGoals.ContactsClick)") {{ WhatsAppNumber.value }} WhatsApp
      .footer-menu__column
        .footer-menu__title Email
        a(:href="MailAddress.link" @click="sendMetrica(YmGoals.ContactsClick)") {{ MailAddress.value }}
  .divider(v-if="!mini")
  .footer-bottom(:class="mini && '_mini'")
    | © 2009 - {{ year }} НПО Система. Все права защищены. Ни одна часть этого сайта
    | не может быть воспроизведена или передана каким-либо образом без
    | письменного разрешения ООО НПО Система
    .footer__contact-us(v-if="!mini")
      | Свяжитесь с нами:
      a(v-for="item in ConnectWithUs" target="_blank" :href="item.link")
        ui-icon(
          clickable
          original-color
          :size="32"
          :key="item.label"
          :icon="item.icon"
        )
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getYear } from "@/utils/formatter/dateFormatter";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { sendMetrica, YmGoals } from "~/utils/logger/metrica";
import { Tabs } from "@/router/tabs";
import { PhoneNumber, FreePhoneNumber, MailAddress, WhatsAppNumber, ConnectWithUs } from "@/const/contacts";
import { version } from '~/package.json'
import UiIconNames from "@/components/ui/icon/UiIconNames";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import AccessedLink from "~/components/ui/links/AccessedLink.vue";

export default defineComponent({
  name: "MainFooter",
  components: {
    AccessedLink,
    UiIcon,
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    }
  },
  setup() {

    const year = getYear();

    const { isAuth } = useUserAccess();

    return {
      year,
      isAuth,
      version,
      sendMetrica,
      YmGoals,
      ConnectWithUs,
      PhoneNumber,
      WhatsAppNumber,
      FreePhoneNumber,
      MailAddress,
      Tabs,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
.footer-menu {
  flex-wrap: wrap;
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
}

.footer-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;
  width: 100%;
  font-size: 12px;

  &._mini {
    padding: 24px 0;
    border-top: 1px solid #d4d7de;
  }

  .footer__contact-us {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
  }
}

.footer {
  display: flex;
  flex-flow: column;
  width: 100%;
  color: var(--main-text-color);
  padding: 24px 24px;
  align-items: center;
  max-width: 1140px;

  font-size: 13px;
  line-height: 18px;

  box-sizing: border-box;

  .footer-menu__block {
    display: flex;
    flex-flow: column;
    gap: 24px;
  }

  .footer-menu__column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 120px;

    .footer-menu__title {
      font-weight: bold;
      font-size: 14px;
    }
  }

  a {
    cursor: pointer;
  }

  a:hover:not(.green) {
    font-weight: 600;
    color: #00b8ff;
  }

  a.green {
    &:hover {
      font-weight: 600;
      color: #25d366;
    }
  }

  .divider {
    display: block;
    height: 1px;
    width: 100%;
    max-width: 1140px;
    margin: 24px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.5);
  }
}

.download-offer {
  display: flex;
  flex-flow: row;
  gap: 2px;

  .download-icon {
    margin: 1px 0;
  }
}

@media (max-width: 1000px) {
  .footer-menu {
    grid-template-columns: repeat(4, minmax(100px, 1fr));
  }
}

@media (max-width: 640px) {
  .footer-menu {
    grid-row-gap: 40px;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }

  .footer-bottom {
    flex-direction: column;
    gap: 16px;
  }
}

@media (max-width: 320px) {
  .footer-menu {
    grid-row-gap: 24px;
    grid-template-columns: repeat(1, minmax(100px, 1fr));
  }

  .footer-bottom {
    flex-direction: column;
    gap: 16px;
  }
}
</style>
